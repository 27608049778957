import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";
import Update from "./Update";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#18252E",
  },
  MainLayout:{
    minHeight:"calc(100vh - 415px)"
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  console.log(history.location);
  return (
    <div className={classes.root}>
       <TopBar /> 
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.MainLayout}>{children}</div>
      <Update />
       <Footer /> 
    </div>
  );
};

export default MainLayout;
