import React from "react";
import {
    Container,
    Typography,
    Box,
    makeStyles,
    Grid,
    Button,
    ListItem,
    List,
    Link,
} from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import TelegramIcon from "@material-ui/icons/Telegram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { useHistory, Link as RouterLink } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    bannerBox2: {
        // backgroundImage: "url(./images/banner/wave.png)",
        backgroundPosition: "center bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "auto",
        borderBottom: "1px solid #108484",
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        backgroundColor: "#0F2025",
        paddingBottom: "3rem",
        paddingTop: "2rem",
        display: "flex",
        justifyContent: "space-between",
    },
    text: {
        "& h1": {
            fontFamily: "'Zen Dots'",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "19px",
            letterSpacing: "0.03em",
            color: "#25FCFF",
        },
        "& a": {
            color: "#25FCFF",
            fontSize: "14px",
            fontStyle: "normal",
            fontFamily: "'Zen Dots'",
            fontWeight: "400", lineHeight: "19px",
            letterSpacing: "0.03em",
            [theme.breakpoints.only("xs")]: {
                fontSize: "10px",
              },
            "&:hover": {
                color: "#AE8C3F",
                textDecoration: "none",
            },
        },

    },
    textflex: {
        display: "flex",
        alignItems: "center",
    },
    socialmediaicon: {
        display: "flex",
        justifyContent: "space-between",
    },
    socialmediaicon1: {
        textAlign: "center",
        "& h1": {
            paddingTop: "10px",
            fontFamily: "'Turret Road'",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "21px",
            letterSpacing: "0.03em",
            color: "#AE8C3F",
            [theme.breakpoints.only("xs")]: {
                fontSize: "14px",
              },
        },
    },

    iconimg: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        marginTop: "9px",
        "& a": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#AE8C3F",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            marginTop: "9px",
        },
        "& svg": {
            fontSize: "20px",
            color: "#000",
            "&:hover": {
                color: "#25FCFF",
                cursor: "pointer",
            },
        },
    },
}));

export default function Ban2() {
    const classes = useStyles();

    return (
        <Box className={classes.bannerBox2}>
            <Container>
                <Grid container spacing={2} style={{ alignItems: "center" }}>
                    <Grid item xs={12} md={4} lg={4}>
                        <Box className={classes.textflex}>
                            <Box className={classes.text}>
                                <List>
                                    <ListItem to="/blog" component={RouterLink}>
                                        Blog
                                    </ListItem>
                                </List>
                            </Box>
                            <Box className={classes.text}>
                                <List>
                                    <ListItem to="/faq" component={RouterLink}>
                                        FAQ
                                    </ListItem>
                                </List>
                            </Box>
                            <Box className={classes.text}>
                                <List>
                                    <ListItem to="/support" component={RouterLink}>
                                        Support
                                    </ListItem>
                                </List>

                            </Box>

                            <Box className={classes.text}>
                                <List>
                                    <ListItem to="/profile" component={RouterLink}>
                                        My Account
                                    </ListItem>
                                </List>

                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}></Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Box className={classes.socialmediaicon}>
                            <Box className={classes.socialmediaicon1}>
                                <Box className={classes.iconimg}>
                                    <a target="_blank" href="https://twitter.com/login">
                                        <TwitterIcon />
                                    </a>
                                </Box>
                                <Typography variant="h1">Twitter</Typography>
                            </Box>
                            <Box className={classes.socialmediaicon1}>
                                <Box className={classes.iconimg}>
                                    <a target="_blank" href="https://www.facebook.com/">
                                        <FaFacebookF />
                                    </a>
                                </Box>
                                <Typography variant="h1">Facebook</Typography>
                            </Box>
                            <Box className={classes.socialmediaicon1}>
                                <Box className={classes.iconimg}>
                                    <a target="_blank" href="https://www.instagram.com/?hl=en">
                                        <AiOutlineInstagram />
                                    </a>
                                </Box>
                                <Typography variant="h1">Instagram</Typography>
                            </Box>
                            <Box className={classes.socialmediaicon1}>
                                <Box className={classes.iconimg}>
                                    <a target="_blank" href="https://www.youtube.com/">
                                        <YouTubeIcon />
                                    </a>
                                </Box>
                                <Typography variant="h1">YouTube</Typography>
                            </Box>

                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>


    );
}
