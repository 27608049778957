import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiInput: {
      underline: {
        "&::before": {
          borderBottom: "1px solid rgb(232, 170, 62) !important",
        },
      },
    },
    MuiSelect: {
      icon: {
        color: "rgb(232, 170, 62)",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "11px",
        color: "rgb(232, 170, 62)",
        fontFamily: "'Turret Road'",
      },
    },
    MuiList: {
      padding: {
        // paddingTop: "0px",
        // paddingBottom: "0px",

        paddingTop: "8px",
        paddingLeft: "8px",
        paddingRight: "8px",
        paddingBottom: "8px",
      },
    },

    MuiLink: {
      underlineHover: {
        "&:hover": {
          textDecoration: "none",
        },
      },
    },

    MuiFormLabel: {
      root: {
        color: "rgb(232, 170, 62)",
        fontSize: "12px",
        fontFamily: "'Turret Road'",
      },
      colorSecondary: {
        "&.Mui-focused": {
          color: "rgb(232, 170, 62)",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "rgb(251 251 251 / 23%)",
      },
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#222",
          borderColor: "#222",
        },
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiPaper: {
      root: {
        color: "#52565c",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundColor: "#0C2D36",
      },
      outlined: {
        padding: "20px",
        width: "100%",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperWidthXs: {
        maxWidth: "544px",
      },
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
      },
      paperWidthSm: {
        maxWidth: "900px !important",
      },
    },
    MuiInputBase: {
      root: {
        color: "rgb(232, 170, 62)",
        fontSize: 11,
      },
      input: {
        fontSize: 10,
        color: "rgb(16 132 132)",
        height: "1.1876em",
        padding: "12px 0px",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiButton: {
      containedSizeLarge: {
        padding: "8px 22px",
        fontSize: "0.8125rem",
      },
      root: {
        color: "rgb(232, 170, 62)",
        fontFamily: "'Zen Dots'",
        fontSize: "12px !important",
      },
      containedSecondary: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "50px",
        fontSize: "14px",
        color: "#000",
        backgroundColor: "rgb(232, 170, 62)",
        padding: "5px 19px",
        fontWeight: "400",
        "&:hover": {
          backgroundColor: "#108484",
          color: "#fff",
        },
      },

      containedPrimary: {
        backgroundColor: "#108484",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "50px",
        color: "#fff",
        fontWeight: "400",
        fontSize: "15px",
        height: "40px",
        lineHeight: " 21px",
        padding: "10px 39px",
        "&:hover": {
          backgroundColor: "rgb(232, 170, 62)",
          color: "#000",
        },
      },
      contained: {
        borderRadius: "50px",
        color: "#f30065",
        fontWeight: 600,
        padding: "5px 19px",

        "&:hover": {
          backgroundColor: "#f30065",

          color: "#fff",
        },
      },
      outlinedPrimary: {
        borderRadius: "50px",
        color: "#300760",
        fontWeight: 600,
        padding: "5px 19px",
        border: "2px solid #300760",
        "&:hover": {
          backgroundColor: "#f30065",
          border: "2px solid #f30065",
          color: "#fff",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      colorTextSecondary: {
        fontSize: "10px",
      },
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "rgb(232, 170, 62)",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "rgb(232, 170, 62)",
      fontSize: "11px",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
