export default {
  h1: {
    fontWeight: 500,
    fontSize: 35,
    fontFamily: "'Zen Dots'",
  },
  h2: {
    fontWeight: 500,
    fontSize: 18,
    fontFamily: "'Turret Road'",
  },
  h3: {
    fontWeight: 500,
    fontSize: 25,
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    fontFamily: "'Zen Dots'",
    color: "#25FCFF",
  },
  h5: {
    fontWeight: 500,
    fontSize: 18,
    fontFamily: "'Zen Dots'",
    color: "#25FCFF",
  },
  h6: {
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "'Zen Dots'",
    color: "#25FCFF",
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "'Zen Dots'",
  },
  body1: {
    fontSize: 16,
    fontFamily: "'Zen Dots'",
  },
  body2: {
    fontSize: "0.875rem",
    lineHeight: "22px",

    fontFamily: "'Turret Road'",
    color: "rgb(232, 170, 62)",
  },
};
