import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/market",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Market")),
  },

  {
    exact: true,
    path: "/buy-packs",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/BuyPacks/Index")),
  },

  {
    exact: true,
    path: "/open-packs",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/OpenPack/Index")),
  },
  // {
  //   exact: true,
  //   path: "/About",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Home/Market")),
  // },
  // {
  //   exact: true,
  //   path: "/market",
  //   layout: HomeLayout,

  //   component: lazy(() => import("src/views/pages/Home/Market")),
  // },
  {
    exact: true,
    path: "/create",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Create/CreateNFT")),
  },
  {
    exact: true,
    path: "/nft-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/NFTDetails")),
  },
  {
    exact: true,
    path: "/my-collection",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Collections/Collections")),
  },

  {
    exact: true,
    path: "/collection-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Author/Author")),
  },
  {
    exact: true,
    path: "/about-page",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/AboutCheems")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Privacy")),
  },
  {
    exact: true,
    path: "/faq",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Faq")),
  },
  {
    exact: true,
    path: "/terms-condition",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Terms&Cond")),
  },

  {
    exact: true,
    path: "/profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/EditProfile/EditProfile")),
  },
  {
    exact: true,
    path: "/blog",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Blog/Blog")),
  },
  {
    exact: true,
    path: "/support",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Support")),
  },
  {
    exact: true,
    path: "/blog-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Blog/BlogDetails")),
  },
  {
    exact: true,
    path: "/resale-nft",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Create/ResellNFT")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
