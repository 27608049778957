import React from "react";
import {
  Container,
  Typography,
  Box,
  makeStyles,
  Grid,
  ListItem,
  List,
  Link,
  Button,
} from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  bannerBox2: {
    // backgroundImage: "url(./images/banner/wave.png)",
    backgroundPosition: "center bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "auto",
    width: "100%",
    maxWidth: "100%",
    overflow: "hidden",
    backgroundColor: " #18252E",
    padding: "30px 0",

    "& li": {
      color: "#AE8C3F",
      fontSize: "16px",
      fontFamily: "'Turret Road'",
      fontWeight: "400",
      lineHeight: "21px",
      paddingTop: "10px",
    },
    "& a": {
      color: "#AE8C3F",
      fontSize: "17px",
      fontFamily: "'Turret Road'",
      fontWeight: "400",
      lineHeight: "21px",
      paddingTop: "10px",
      [theme.breakpoints.only("xs")]: {
        fontSize: "14px",
      },
      "&:hover": {
        color: "#25FCFF",
        textDecoration: "none",
      },
    },
  },

  lionbox: {
    display: "flex",
    alignItems: "center",
    
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
      order:"2",
    },

  },
  lionbox1: {
    marginTop: "11px",
  },
  lionbox2: {
    marginLeft: "1rem",
    "& h1": {
      fontFamily: "'Zen Dots'",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "22px",
      lineHeight: "31px",
      letterSpacing: "-0.01em",
      color: "#25FCFF",
      [theme.breakpoints.only("xs")]: {
        fontSize:"18px",
      },
    },
    "& h2": {
      fontFamily: "'Turret Road'",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "17px",
      lineHeight: "24px",
      letterSpacing: "0.03em",
      color: "#AE8C3F",
      [theme.breakpoints.only("xs")]: {
        fontSize: "14px",
      },
    },
  },
  abouticon: {
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
      order:"1",
    },
    "& h2": {
      [theme.breakpoints.only("xs")]: {
        textAlign: "center",
      },
    },
    "& a": {
      color: "#25FCFF",
      fontSize: "14px",
      fontStyle: "normal",
      fontFamily: "'Zen Dots'",
      fontWeight: "400",
      letterSpacing: "-0.01em",
      "&:hover": {
        color: "#25FCFF",
        textDecoration: "none",
      },
    },
  },

  abouticon1: {
    display: "flex",
    justifyContent: "end",
    "& a": {
      color: "#25FCFF",
      fontSize: "14px",
      fontStyle: "normal",
      fontFamily: "'Turret Road'",
      fontWeight: "400",
      letterSpacing: "-0.01em",
      "&:hover": {
        color: "#25FCFF",
        textDecoration: "none",
      },
    },
  },
  bottomText: {

    [theme.breakpoints.only("xs")]: {
      textAlign: "center"
    },

  },
    gridbox:{order:"0",
    "@media(max-width:600px)":{order:"1",},
  },
      gridbox1:{order:"1","@media(max-width:600px)":{order:"0",},},
}));

export default function Ban2() {
  const classes = useStyles();
  const history = useHistory();

  return (


    <Box className={classes.bannerBox2}>
      <Container>
        <Grid container spacing={2} style={{ alignItems: "center" }}>
          <Grid item xs={12} md={6} lg={6} sm={6} className={classes.gridbox}>
            <Box className={classes.lionbox}>
              <Box className={classes.lionbox1}>
                <img src="./images/banner/lion.png" />
              </Box>
              <Box className={classes.lionbox2}>
                <Typography variant="h1">CheemsArena</Typography>
                <Typography variant="h2">
                  2022 © CheemsArena. All Rights Reserved.
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={6} sm={6} align="right" className={classes.gridbox1}>

            <Box className={classes.abouticon} mb={1}>

              <Typography variant="h2">
                <Link to="/about-page" component={RouterLink}>
                  About CheemsArena
                </Link>

              </Typography>

            </Box>

            <Typography variant="h2" className={classes.bottomText}>
              <Link to="/privacy-policy" component={RouterLink}>
                Privacy Policy
              </Link>
              &nbsp;  &nbsp;
              <Link to="/terms-condition" component={RouterLink}>

                Terms & Conditions
              </Link>
            </Typography>

          </Grid>

        </Grid>
      </Container>
    </Box>


  );
}
